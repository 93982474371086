import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

const TableBody = ({ data, columns }) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return get(item, column.path) ?? '-';
  };

  const createKey = (item, column) => {
    return (item?.id || 1) + (column?.path || column?.key) + Math.random();
  };

  return (
    <StyledTBody>
      {data?.map((item, index) => (
        <tr key={Math.random()}>
          {columns?.map((column) => (
            <td key={createKey(item, column)} className={column.alignBody}>
              {renderCell(item, column)}
            </td>
          ))}
        </tr>
      ))}
    </StyledTBody>
  );
};

const StyledTBody = styled.tbody`
  tr:last-child > td {
    border-bottom: 0;
  }
`;

export default TableBody;
