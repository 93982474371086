import React, { useState } from 'react'
import StyledText from '../../Components/StyledComponents/StyledText'
import theme from '../../Services/Utils/themes'
import StyledIconInput from '../../Components/StyledComponents/StyledIconInput'
import StyledAccordion from '../../Components/StyledComponents/StyledAccordian'
import DocumentListing from './DocumentListing'
import styled from 'styled-components'
import StyledButton from '../../Components/StyledComponents/StyledButton'
import AddIcon from '../../Components/Svgicons/AddIcon'
import DocumentPostingModal from '../../Admin/Modals/DocumentPostingModal'
import PaginationPerPage from '../../Components/pagination/PaginationPerPage'
import { AddressContainer, DocumentListContainer, MainCommonContainer, PaginationCommonContainer, StyledContainer, StyledDocumentListContainer } from '../../Components/StyledComponents/CommonStyled'
import { svenskaLs } from '../../Services/Utils/localStorage'
import { USERTYPE, useWindowSize } from '../../Services/Utils/helper'
import * as Yup from 'yup';
import { useGetRequestStatusQuery, useGetRequestedDocsQuery, useRequestDocumentMutation } from '../../Services/DashboardApi/dashboardApiSlice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import PaymentModal from '../../Admin/Modals/PaymentModal'
import SharedDocZeroState from '../../Components/Svgicons/SharedDocZeroState'
import DocumentPostingSkeleton from '../../Components/Skeletons/DocumentPostingSkeleton'

const DocumentPosting = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const lang = svenskaLs.getItem("lng");

    const initialFilters = {
        perPage: 5,
        page: 1,
        query: "",
        sortedColumn: { path: "createdAt", order: "DESC" },
    };
    const [timer, setSearchTimer] = useState(null);
    const [filters, setFilters] = useState({ ...initialFilters });
    const isSmallScreen = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("Requested");
    const userType = svenskaLs.getObject("userType");
    const [requestDocument, { isLoading: requestLoading }] = useRequestDocumentMutation();
    const { data, isLoading, refetch: refetchStatus } = useGetRequestStatusQuery();
    const {
        data: getRequestedDocs,
        refetch: refetchDocs,
        isLoading: documentLoads
    } = useGetRequestedDocsQuery(
        {
            perPage: filters.perPage,
            page: filters.page,
            query: filters.query && filters.query,
            sort: filters.sortedColumn.path || filters.sort,
            sortDir: filters.sortedColumn.order || filters.sortDir,
            ...filters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );


    const handleSearch = (evt) => {
        clearTimeout(timer);
        const query = evt.target.value;
        const newTimer = setTimeout(() => {
            setFilters({ ...filters, page: 1, query: query });
        }, 1000);
        setSearchTimer(newTimer);
    };

    const handleSubmit = (values) => {                
        values.fileId = values?.fileId?.map(item => item?.value);
        requestDocument(values).then((resp) => {
            toast.success(t('common:documentPostingToast'));
            setShowModal(false);
            refetchDocs();
            refetchStatus();
        }
        ).catch((e) => toast.error(e.message));

    }

    const validationSchema = Yup.object().shape({
        fileId: Yup.array().min(1, t("common:selectAtleast")).required("required")
            .required(t("common:selectAtleast")),
        name: Yup.string().required('Name is required'),
        phoneNumber: Yup.string()
            .required(t("common:phoneRequired"))
            .matches(/^[0-9]+$/, t("common:phoneNumberOnlyDigits")),
        email: Yup.string()
            .required(t("common:emailRequired"))
            .email(t("common:invalidEmail")),
        country: Yup.string().required(t("common:countryRequired")),
        city: Yup.string().required(t("common:cityRequired")),
        town: Yup.string().required(t("common:townRequired")),
        streetAddress: Yup.string().required(t("common:streetAddressRequired")),
        postalCode: Yup.string().required(t("common:postalCodeRequired")),
    });

    const handlePerPageChange = (perPage) => {
        setFilters((prevState) => ({
            ...prevState,
            perPage: perPage.target.innerText,
            page: 1,
        }));
    };

    const handlePageChange = ({ selected }) => {
        setFilters((prevState) => ({
            ...prevState,
            page: selected + 1,
        }));
    };

    const handleConditionModal = () => {

        if (data?.isFree) {
            setShowModal(true);
        }
        else {
            setShowPaymentModal(true);
        }

    }



    return (
        <div className='bg-white'>
            {/* <div className="container-fluid"> */}
            <Container lang={lang}>
                <div className=" text-md-start">
                    <div className='d-flex flex-column py-3'>
                        <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>
                            {t('common:clientDocumentPosting')}
                        </StyledText>
                        <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray} className='mb-0' truncatedlines={1}>
                            {t('common:allCurrentAndRecentRequest')}
                        </StyledText>
                    </div>
                </div>
                {/* <div className="col-md-6 col-sm-12"> */}
                <SeacrhContainer className='d-flex align-items-center justify-content-end gap-2' lang={lang}>
                    {/* <ResponsiveSearch onChange={handleSearch} placeholder={t('common:searchDocument')} lang={lang}/> */}
                    <IconButtonContainer lang={lang}>

                        <StyledButton variant="primary" icon={<AddIcon />} className={`icon-button ${isLoading ? 'feature-pending' : ''}`} onClick={handleConditionModal} />

                    </IconButtonContainer>
                    <TextButtonContainer lang={lang}>
                        <StyledButton variant="primary" icon={<AddIcon />} className={`w-100 text-button ${isLoading ? 'feature-pending' : ''}`} wd={204} onClick={handleConditionModal}>
                            {t('common:requestDocument')}
                        </StyledButton>
                    </TextButtonContainer>

                </SeacrhContainer>
                {/* </div> */}
            </Container>
            {/* </div> */}
            <StyledContainer  height={isSmallScreen ? '271px' : "287px"} zeroState={!documentLoads && (getRequestedDocs?.data?.length === 0 || getRequestedDocs?.data?.length === undefined)}>
                {
                    !documentLoads && (getRequestedDocs?.data?.length === 0 || getRequestedDocs?.data?.length === undefined) ? <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <div className="d-flex align-items-center justify-content-center">
                            <SharedDocZeroState />
                        </div>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={600}
                            $fontSize="20px"
                            className="text-center mt-3"
                        >
                            {t('common:noDocumentPosted')}
                        </StyledText>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={500}
                            $fontSize="14px"
                            className="text-center"
                        >
                            {t('common:allCurrentAndRecentRequest')}
                        </StyledText>
                    </div> : documentLoads ? <DocumentPostingSkeleton /> :




                        getRequestedDocs?.data?.map((item, index) => (                            
                            <StyledAccordion
                                key={index}
                                data={item}
                                index={index}
                                isOpen={isOpen === index}
                                setIsOpen={setIsOpen}
                                setSelectedValue={setSelectedValue}
                                selectedValue={selectedValue}
                                onToggle={() => setIsOpen(isOpen === index ? null : index)}
                            >
                                <div className='px-0 px-md-5'>
                                    <StyledDocumentListContainer >
                                        {item?.clientDocPosting?.map((item, index, array) => (
                                            <DocumentListing file={item} key={index} index={index} totalFiles={array.length} />
                                        ))}

                                    </StyledDocumentListContainer>
                                    {userType === USERTYPE.ADMIN ? <hr className='mt-3' /> : null}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {/* {userType === USERTYPE.ADMIN ? <div className='d-flex flex-column'>
                                            <StyledText>
                                                <b>Name</b>&nbsp;:&nbsp;Joesph Stanley
                                            </StyledText>
                                            <StyledText>
                                                <b>Email Address</b>&nbsp;:&nbsp;stanleyjoesph45@gmail.com
                                            </StyledText>
                                            <StyledText><b>Phone</b>&nbsp;:&nbsp;0 000 000 0000</StyledText>

                                        </div> : null} */}
                                        <AddressContainer className='mt-4'>
                                            <StyledText>
                                                <b className='fw-semibold'>{t('common:requestedAddress')}</b>&nbsp;:&nbsp;{item?.streetAddress},&nbsp;{item?.postalCode}&nbsp;{item?.city},&nbsp;{item?.country}
                                            </StyledText>
                                        </AddressContainer>
                                    </div>

                                </div>
                            </StyledAccordion>
                        ))}

            </StyledContainer>

            {documentLoads ? null : !documentLoads && (getRequestedDocs?.data?.length === 0 || getRequestedDocs?.data?.length === undefined) ? null : <PaginationCommonContainer >
                <PaginationPerPage
                    perPage={getRequestedDocs?.perPage || 0}
                    pageNo={getRequestedDocs?.page || 0}
                    onPerPageChange={handlePerPageChange}
                    totalCount={getRequestedDocs?.count || 0}
                    onPageChange={handlePageChange}
                />
            </PaginationCommonContainer>}

            {showModal && <DocumentPostingModal show={showModal} onHide={() => setShowModal(false)} handleSubmit={handleSubmit} validationSchema={validationSchema} loading={requestLoading} disableLoading={requestLoading} />}
            {showPaymentModal && <PaymentModal show={showPaymentModal} onHide={() => { setShowPaymentModal(false); setShowModal(true) }} onClose={()=>setShowPaymentModal(false)}/>}


        </div>
    )
}



const Container = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding:15px 35px 0px;
@media (max-width: 768px) {
    padding: 0 15px;
}
//  @media (max-width: ${({ lang }) => (lang === 'en' ? '320px' : '320px')}) {
//             flex-direction: column;
//             gap:10px;
        
//     }
`;

const SeacrhContainer = styled.div`
display:flex;
align-items:center;
justify-content:end;
gap:10px;
//  @media (max-width: ${({ lang }) => (lang === 'en' ? '320px' : '320px')}) {
// width: 100%;
        
//     }
`;


const TextButtonContainer = styled.div`
display:block;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '816px' : '893px')}) {
            display: none;
        
    }
`;
const IconButtonContainer = styled.div`
display:none;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '816px' : '893px')}) {
            display: block;
        
    }
`;

const ResponsiveSearch = styled(StyledIconInput)`
width: 364px;
max-width: none;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '1053px' : '1137px')}) {
            width: 100%;
        
    }

`
export default DocumentPosting