import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import dottedBg from "../../Components/Svgicons/Dottedbg.png";
import theme from "../../Services/Utils/themes";
import SendIcon from "../Svgicons/SendIcon";
import StyledButton from "../StyledComponents/StyledButton";
import useAuth from "../../Services/Hooks/useAuth";
import { useTranslation } from "react-i18next";
import {
  DefaulImage,
  filterHtml,
  formatFileSize,
  getIconByFileExtension,
  handleDownload,
  handleErrorImg,
  handleViewDocs,
  stripTags,
} from "../../Services/Utils/helper";
import StyledText from "../StyledComponents/StyledText";
import StyledImage from "../StyledComponents/StyledImage";
import moment from "moment/moment";
import { isEmpty, orderBy } from "lodash";
import DownloadIcon from "../Svgicons/DownloadIcon";
import StyledTextEditor from "../TextEditor/StyledTextEditor";
import ChatZeroStateIcon from "../Svgicons/ChatZeroStateIcon";
import ThreeDotsIcon from "../Svgicons/ThreeDotsIcon";
import DeleteIcon from "../Svgicons/DeleteIcon";
import useAdminAuth from "../../Services/Hooks/useAdminAuth";
import { StyledChatSkeleton } from "../StyledComponents/StyledChatSkeleton";
import { useLocation } from "react-router-dom";


const StyledChatBox = ({
  className,
  chatFor,
  messageData,
  messageEndRef,
  handleSubmit,
  handleAttachment,
  handleDeleteMessage,
  openRef,
  DeleteFile,
  HandleSetMessage,
  fileref,
  file,
  message,
  fileProgess,
  // handleDownload,
  // handleViewDocs,
  loadMessage,
  submitLoading,
  setFilters,
  filters,
  handleUploadFile,
  showDropdown,
  setShowDropdown,
  setFileCategory,

}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);
  const { admin } = useAdminAuth();
  const { user } = useAuth();
  var previousAdmin = null;
  var previousUser = null;

  const loc = useLocation();
  const path = loc?.pathname?.includes('admin')


  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (messageData.length && scrollTop === 0 && scrollTop < scrollHeight - clientHeight) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: prevFilters.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [filters?.page, messageData]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView();
      }
    };

    scrollToBottom();
    // eslint-disable-next-line
  }, [messageData]);



  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          setShowFilter(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(openRef);

  const cleanedMessage = message?.replace(/<p><br><\/p>/g, '')?.trim();
  const textOnlyContent = cleanedMessage?.replace(/<\/?[^>]+(>|$)/g, '')?.trim();


  return (
    //  <div className=" d-flex align-items-center justify-content-center">
    <StyledContainer
      className={`${className
        ? className
        : "bg-white col-12 col-xl-7 rounded pt-4 pb-3"
        }`}
      style={chatFor === "admin" ? { top: "50%" } : null}
      chatType={chatFor}
    >
      <StyledChat className="mb-3 ref" ref={containerRef} path={path}>
        {!loadMessage && messageData?.length === 0 ? (
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <ChatZeroStateIcon />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={700}
              $fontSize="22px"
              className="text-center mt-3"
              as={"div"}
            >
              {t("common:NoConversationTitle")} !
            </StyledText>
            <StyledText
              color={theme.gray}
              $fontWeight={500}
              $fontSize="14px"
              className="text-center px-3 px-md-5 mt-3"
              as={"div"}
            >
              {t("common:NoConversationDesc")}
            </StyledText>
          </div>
        ) : loadMessage ? <StyledChatSkeleton /> : (
          orderBy(messageData, "createdAt")?.map((chat) => {
            const isSameAdmin = chat?.adminId === previousAdmin;
            const isSameUser = chat?.userId === previousUser;

            previousAdmin = chat?.adminId;
            previousUser = chat?.userId;
            
            return (
              <div
                key={chat.id}
                className={
                  ((user?.id === chat?.userId &&
                    chat.messageDirection === "OUTGOING") ||
                    (admin?.id === chat?.admin?.id &&
                      chat.messageDirection === "INCOMING"))
                    ? "d-flex mb-2 justify-content-end"
                    : "d-flex mb-2 justify-content-start"
                }
              >
                {(user?.id === chat?.userId &&
                  chat.messageDirection === "OUTGOING") ||
                  (admin?.id === chat?.admin?.id &&
                    chat.messageDirection === "INCOMING") ? 
                    (
                  <StyledChatView
                    className="d-flex flex-column justify-content-end align-items-end mw-100 my-1"
                    onMouseEnter={() => setOptions(chat?.id)}
                    onMouseLeave={() => {
                      setOptions(null);
                      setShowFilter(false);
                    }}
                  >
                    <div className=" d-flex align-items-center justify-content-between gap-2 w-100 mb-1">
                      <div className="d-flex align-items-center gap-2">
                        <StyledText
                          color={theme.secondary}
                          $fontWeight={500}
                          $fontSize="12px"
                        >
                          {moment(chat?.createdAt).format("DD MMM YY h:mm a")}
                        </StyledText>

                        {chat?.id === options && (
                          <div className=" position-relative">
                            <StyledButton
                              icon={<ThreeDotsIcon />}
                              variant="icon"
                              className={` p-0 rounded border-0  ${showFilter ? "active-sort" : null
                                }`}
                              onClick={() => setShowFilter(!showFilter)}
                            />

                            {showFilter ? (
                              <StyledFilter
                                className=" position-absolute bg-white p-2 rounded-3 shadow"
                                ref={openRef}
                              >
                                <StyledButton
                                  variant="white"
                                  className="w-100 d-flex align-items-center justify-content-start  rounded"
                                  onClick={() => handleDeleteMessage(chat?.id)}
                                  icon={
                                    <DeleteIcon size={18} className="me-1" />
                                  }
                                >
                                  {t("common:textChatDeleteBtn")}
                                </StyledButton>
                              </StyledFilter>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {(!isSameAdmin || !isSameUser) ? (
                        <div className="d-flex align-items-center gap-2">
                          <StyledText
                            color={theme.secondary}
                            $fontWeight={500}
                            $fontSize="14px"
                          >
                            {user?.id === chat?.userId &&
                              chat.messageDirection === "OUTGOING"
                              ? t('common:You')
                              : admin?.id === chat?.admin?.id &&
                              chat.messageDirection === "INCOMING" &&
                              t('common:You')}
                          </StyledText>
                          <StyledImage
                            height={"30px"}
                            width={"30px"}
                            className="rounded-circle"
                          >
                            <img
                              style={{ objectFit: "cover" }}
                              src={(chat?.messageDirection === "OUTGOING") ?  chat?.user?.signedUrl  : chat?.admin?.signedUrl  || DefaulImage.image
                              }
                              alt="img"
                              onError={handleErrorImg}
                            />
                          </StyledImage>
                        </div>
                      ) : null}
                    </div>

                    {!isEmpty(chat?.file) && !isEmpty(chat?.message) ? (
                      <StyledRighMessageView className="bg-primary  p-3 d-flex flex-column">
                        {chat?.message?.messageBody ? (
                          <StyledText
                          as="label"
                            color={theme.white}
                            $fontWeight={400}
                            $fontSize="13px"
                            dangerouslySetInnerHTML={{ __html: chat?.message?.messageBody }}
                            // wordbreak= {true}
                            className="mb-0"

                          />

                        ) : null}
                        <div className="d-flex align-items-center border rounded-2 p-2 bg-white mt-2 justify-content-between">
                          <div
                            className="d-flex align-items-center gap-2"
                            onClick={() => handleViewDocs(chat?.file?.s3Object?.signedUrl)}
                          >
                            {getIconByFileExtension(chat?.file?.fileName)}
                            <div className="d-flex flex-column">
                            <StyledText
                              color={theme.greenishGray}
                              $fontWeight={400}
                              $fontSize="13px"
                              truncatedlines={1}
                              whiteSpace="break-spaces"


                            >
                              {chat?.file?.fileName}
                            </StyledText>
                            <StyledText
                              color={theme.gray}
                              $fontWeight={500}
                              $fontSize="11px"
                            >
                              {formatFileSize(chat?.file?.s3Object?.fileSize)}
                            </StyledText>
                            </div>
                          </div>
                          {/* <div
                            className="cursor-pointer text-muted bg-light-gray p-2 rounded"
                            onClick={() => handleDownload(chat?.file)}
                          >
                            <DownloadIcon size="20" className="" />
                          </div> */}
                        </div>
                      </StyledRighMessageView>
                    ) : isEmpty(chat?.file) ? (
                      <StyledRighMessageView className="bg-primary p-3 d-flex flex-column">
                        <StyledText
                          color={theme.white}
                          $fontWeight={400}
                          $fontSize="13px"
                          className="mb-0"
                          dangerouslySetInnerHTML={{ __html: chat?.message?.messageBody }}
                          // wordbreak= {true}


                        />
                      </StyledRighMessageView>
                    ) : (
                      isEmpty(chat?.message) && (
                        <StyledRighMessageView className="bg-white d-flex p-3 gap-2 align-items-center border cursor-pointer" onClick={() => handleViewDocs(chat?.file?.s3Object?.signedUrl)}>

                          <div>
                            {getIconByFileExtension(chat?.file?.fileName)}
                          </div>
                          <div className="d-flex flex-column">
                            <StyledText
                              color={theme.greenishGray}
                              $fontWeight={500}
                              $fontSize="13px"
                              whiteSpace="break-spaces"
                              truncatedlines={1}
                            >
                              {chat?.file?.fileName}
                            </StyledText>
                            <StyledText
                              color={theme.gray}
                              $fontWeight={500}
                              $fontSize="11px"
                            >
                              {formatFileSize(chat?.file?.s3Object?.fileSize)}
                            </StyledText>
                          </div>
                        </StyledRighMessageView>
                      )
                    )}
                  </StyledChatView>
                ) : (
                  <StyledChatView className="d-flex flex-column mw-100 my-2">
                   
                      <div className={` d-flex align-items-center ${ (!isSameAdmin || !isSameUser) ? 'justify-content-between' : 'justify-content-end'} gap-2 w-100 mb-1`}>
                      {(!isSameAdmin || !isSameUser) ? (  <div className="d-flex align-items-center gap-2">
                          <StyledImage
                            height={"30px"}
                            width={"30px"}
                            className="rounded-circle"
                          >
                            <img
                              style={{ objectFit: "cover" }}
                              src={
                              
                                (chat?.messageDirection === "INCOMING") ?  chat?.admin?.signedUrl  : chat?.user?.signedUrl
                                  ||
                                DefaulImage.image
                              }
                              alt="img"
                              onError={handleErrorImg}
                            />
                          </StyledImage>
                          <StyledText
                            color={theme.secondary}
                            $fontWeight={500}
                            $fontSize="14px"
                          >
                            {`${chat?.admin
                              ? chat?.admin?.firstName
                              : chat?.user?.firstName
                              } ${chat?.admin
                                ? chat?.admin?.lastName
                                : chat?.user?.lastName
                              }`}
                          </StyledText>
                        </div>) : null}
                        <StyledText
                          color={theme.secondary}
                          $fontWeight={500}
                          $fontSize="12px"
                        >
                          {moment(chat?.createdAt).format(
                            "DD MMM YY h:mm a"
                          )}
                        </StyledText>
                      </div>
                    
                    {!isEmpty(chat?.file) && !isEmpty(chat?.message) ? (
                      <StyledLeftMessageView className="bg-light-gray  p-3 d-flex flex-column">
                        <StyledText
                          color={theme.gray}
                          $fontWeight={400}
                          $fontSize="13px"
                          // className="text-truncate"
                          // wordbreak= {true}

                          dangerouslySetInnerHTML={{ __html: chat?.message?.messageBody }}

                        />
                        <div className="d-flex align-items-center border rounded-2 p-2 bg-white mt-2 justify-content-between">
                          <div
                            className="d-flex align-items-center gap-2"
                            onClick={() => handleViewDocs(chat?.file?.s3Object?.signedUrl)}
                          >
                            {getIconByFileExtension(chat?.file?.fileName)}
                            <div>
                            <StyledText
                              color={theme.greenishGray}
                              $fontWeight={500}
                              $fontSize="13px"
                              whiteSpace="break-spaces"
                              truncatedlines={1}
                            >
                              {chat?.file?.fileName}
                            </StyledText>
                            <StyledText
                              color={theme.gray}
                              $fontWeight={500}
                              $fontSize="11px"
                            >
                              {formatFileSize(chat?.file?.s3Object?.fileSize)}
                            </StyledText>
                            </div>
                          </div>
                          {/* <div
                            className="cursor-pointer text-muted bg-light-gray p-2 rounded"
                            onClick={() => handleDownload(chat?.file)}
                          >
                            <DownloadIcon size="20" className="" />
                          </div> */}
                        </div>
                      </StyledLeftMessageView>
                    ) : isEmpty(chat?.file) ? (
                      <StyledLeftMessageView className="bg-light-gray  p-3 d-flex flex-column">
                        <StyledText
                          color={theme.gray}
                          $fontWeight={400}
                          $fontSize="13px"
                          className="mb-0"
                          as={"p"}
                          // wordbreak= {true}

                          dangerouslySetInnerHTML={{ __html: chat?.message?.messageBody }}

                        />
                      </StyledLeftMessageView>
                    ) : (
                      isEmpty(chat?.message) && (
                        <StyledLeftMessageView className="bg-white d-flex p-3 gap-2 align-items-center border" onClick={() => handleViewDocs(chat?.file?.s3Object?.signedUrl)}>

                          <div>
                            {getIconByFileExtension(chat?.file?.fileName)}
                          </div>
                          <div className="d-flex flex-column">
                            <StyledText
                              color={theme.greenishGray}
                              $fontWeight={500}
                              $fontSize="14px"
                              truncatedlines={1}
                              whiteSpace="break-spaces"
                            >
                              {chat?.file?.fileName}
                            </StyledText>
                            <StyledText
                              color={theme.gray}
                              $fontWeight={500}
                              $fontSize="11px"
                            >
                              {formatFileSize(chat?.file?.s3Object?.fileSize)}
                            </StyledText>
                          </div>
                        </StyledLeftMessageView>
                      )
                    )}
                  </StyledChatView>
                )}
                <div ref={messageEndRef} />
              </div>
            );
          })
        )}
      </StyledChat>
      <StyledTextEditor
        name="messageBody"
        fileProgess={fileProgess}
        border={`1px solid ${theme.lavenderGray} `}
        borderradius="3px"
        placeholder={t("common:textAreaPlaceholder")}
        className="w-100 position-relative"
        bgcolor={theme.snowWhite}
        handleAttachment={handleAttachment}
        fileref={fileref}
        file={file}
        value={message}
        removeFile={DeleteFile}
        onChange={HandleSetMessage}
        setFileCategory={setFileCategory}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      >
        <StyledButton
          type="submit"
          variant=""
          icon={<SendIcon />}
          disabled={submitLoading}
          loading={submitLoading}
          className={`cursor-pointer border-0 ${fileProgess || (!textOnlyContent && !file) ? "feature-pending" : ""}`}
          onClick={handleSubmit}
        />
      </StyledTextEditor>
    </StyledContainer>
    //  </div>
  );
};

const StyledContainer = styled.div`
  background-image: url(${dottedBg});
  padding: 10px 35px;
  // position: absolute;
  // left: 50%;
  // top: 55%;
  // transform: translate(-50%, -50%);

  @media screen and (max-width: ${({ chatType }) => (chatType !== 'admin' && '1227px')} ) {
      padding: 10px 20px;

  }

  

  width: ${({ chatType }) => chatType === "admin" ? 'calc(100% - 250px)' : '100%'}!important;

    @media (max-width: ${({ chatType }) => (chatType === 'admin' && '1183px')}) {
            width: 100% !important;
        
    }
  height: calc(100vh - 72px);
  @media screen and (max-width: 768px) {
    top: 63%;
  padding: 15px;

  }
  @media screen and (max-width: 575px) {
    top: 65%;
  }
`;

const StyledChatView = styled.div`
  width: auto;
  max-width: 80% !important;
`;

const StyledChat = styled.div`
  height: calc(100vh - 350px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledRighMessageView = styled.div`
  border-radius: 10px 0px 10px 10px;
  max-width: 100%;
  flex-wrap: nowrap;
  // word-break: break-all;
  margin-right: 40px;
`;

const StyledLeftMessageView = styled.div`
  border-radius: 1px 10px 10px 10px;
  width: fit-content;
  max-width: 100%;
  flex-wrap: nowrap;
  // word-break: break-all;
  margin-left: 40px;
`;

const StyledFilter = styled.div`
  width: 203px;
  right: 0px;
  top: 20px;
  @media screen and (max-width: 768px) {
    left: 0;
    right: unset;
  }
`;

export default StyledChatBox;
