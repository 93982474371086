import React, { useEffect, useRef, useState } from "react";
import {
  useDeleteMessageMutation,
  useGetConversationQuery,
  usePostConversationMutation,
  usePreSignedUrlMutation,
} from "../../Services/DashboardApi/dashboardApiSlice";
import { cloneDeep, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import StyledChatBox from "../../Components/Chat/StyledChatBox";
import { toast } from "react-toastify";
import { SetSharedDocs } from "../../Services/SharedDocumentsSlice/SharedDocumentSlice";
import { apiSlice } from "../../Services/ApiSlice/apiSlice";
import { useTranslation } from "react-i18next";

const ConversationHistory = ({ test }) => {
  const {t} = useTranslation();
  const messageEndRef = useRef(null);
  const { token } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [deleteMessage] = useDeleteMessageMutation();
  const openRef = useRef(null);
  const [getPreSignedUrl] =
    usePreSignedUrlMutation();

  const initialFilters = {
    perPage: 20,
    page: 1,
    query: "",
  };
  const [filters, setFilters] = useState({ ...initialFilters });

  const [data, setData] = useState([]);
  const {
    data: getConversation,
    refetch: refetchConversation,
    isLoading: loadMessage,
  } = useGetConversationQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );


  useEffect(() => {
    if (getConversation?.data?.conversation?.conversation?.length) {
      let response =
        getConversation?.data?.conversation?.conversation &&
        cloneDeep([
          ...getConversation?.data?.conversation?.conversation,
        ]).reverse();
      setData((prev) => uniqBy(response?.concat(prev), "id"));
    }
  }, [getConversation?.data?.conversation?.conversation]);

  const [postConversation, { isLoading: submitLoading }] =
    usePostConversationMutation();

  const [message, setMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileCategory, setFileCategory] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const fileref = useRef(null);

  const [progress, setProgress] = useState(false);


  // const handleSubmit = () => {


  //   if (message|| file) {
  //     let payloadData = {
  //       message: message !== "<p><br></p>" ? message : undefined,
  //       originalFilename: file ? file?.file?.name : undefined,
  //       s3Key: file ? file?.key : undefined,
  //       categoryId: fileCategory ? fileCategory?.id : undefined,
  //     };
  //     postConversation(payloadData).then(() => {
  //       refetchConversation();
  //       setMessage("");
  //       setFile(null);
  //       setFilters(initialFilters);
  //       setFileCategory("");
  //       dispatch(SetSharedDocs({ values: true }));
  //       // fileref.current.value = null;
  //     });
  //   } else {
  //     toast.error("Cannot send empty message");
  //   }
  // };

  const handleSubmit = () => {
    const cleanedMessage = message?.replace(/<p><br><\/p>/g, '')?.trim();
    const textOnlyContent = cleanedMessage?.replace(/<\/?[^>]+(>|$)/g, '')?.trim();
  
    if (textOnlyContent !== '' || file) {
      let payloadData = {
        message: textOnlyContent !== '' ? cleanedMessage : undefined,
        originalFilename: file ? file?.file?.name : undefined,
        s3Key: file ? file?.key : undefined,
        categoryId: fileCategory ? fileCategory?.id : undefined,
      };
  
      postConversation(payloadData).then(() => {
        refetchConversation();
        setMessage("");
        setFile(null);
        setFilters(initialFilters);
        setFileCategory("");
        dispatch(SetSharedDocs({ values: true }));
      });
    } else {
      toast.error(t("common:emptyMessage"));
    }
  };
  


  const hanldeRemoveFile = () => {
    setFile(null);
  };

  const DeleteMessage = (id) => {
    deleteMessage(id).then((resp) => {
      dispatch(apiSlice.util.resetApiState());
      setData((prev) => prev.filter((item) => item.id !== id));
      refetchConversation();
      dispatch(SetSharedDocs({ values: true }));
    });
  };

  const handleUploadFile = async (e) => {
    setShowDropdown(false);
    const file = e?.target?.files[0];
    setFile({ file });
    setProgress(true);
    if (!file) return;
    const {
      data: { response: responseData },
    } = await getPreSignedUrl({
      fileName: file.name,
      fileType: file.type,
    });
    if (!responseData?.url) {
      return;
    }
    await fetch(`${responseData?.url}`, {
      method: responseData?.method,
      headers: {
        Accept: "application/json",
        "Content-Type": file.type,
      },
      body: file,

    });
    setFile({ file, key: responseData.key });
    setProgress(false);
  };


  return (
    <>
      <StyledChatBox
        fileProgess={progress}
        messageData={data}
        token={token}
        messageEndRef={messageEndRef}
        handleSubmit={handleSubmit}
        handleAttachment={handleUploadFile}
        handleDeleteMessage={DeleteMessage}
        openRef={openRef}
        DeleteFile={hanldeRemoveFile}
        HandleSetMessage={(value) => setMessage(value)}
        fileref={fileref}
        file={file?.file}
        message={message}
        loadMessage={loadMessage}
        submitLoading={submitLoading}
        setFilters={setFilters}
        filters={filters}
        setFileCategory={setFileCategory}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
    </>

  );
};



export default ConversationHistory;
