import React, { useState } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { styled } from "styled-components";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import DeleteModal from "../Modals/DeleteModal";
import {
  USERTYPE,
  downloadUrl,
  handleViewDocs,
  useWindowSize,
} from "../../Services/Utils/helper";

import AddIcon from "../../Components/Svgicons/AddIcon";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AddDocumentModal from "../Modals/AddDocumentModal";
import DownloadIcon from "../../Components/Svgicons/DownloadIcon";
import { useDeleteDocumentsMutation, useGetDocumentsQuery } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import PaginationPerPage from "../../Components/pagination/PaginationPerPage";
import StyledIconInput from "../../Components/StyledComponents/StyledIconInput";
import {  GridCardContainer, PaginationCommonContainer, StyledContainer } from "../../Components/StyledComponents/CommonStyled";
import StyledResourceCard from "../../Components/StyledComponents/StyledResourceCard";
import ViewIcon from "../../Components/Svgicons/ViewIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import ResourcesZeroState from "../../Components/Svgicons/ResourcesZeroState";
import CardSkeleton from "../../Components/Skeletons/CardSkeleton";

const Resources = () => {
  const { t } = useTranslation();
  let userType = svenskaLs.getObject("userType");
  const lang = svenskaLs.getItem("lng") || "swe";

  const isSmallScreen = useWindowSize();
  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentsMutation();
  const [deleteData, setDeleteData] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [timer, setSearchTimer] = useState(null);
  const [addDoc, setAddDoc] = useState(false);
  const [isEdit, setIsEdit] = useState({ data: {}, state: false });


  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  // eslint-disable-next-line
  const {
    data: getDocuments,
    isLoading,
    refetch: refetchDocs,
  } = useGetDocumentsQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || filters.sort,
      sortDir: filters.sortedColumn.order || filters.sortDir,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );







  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };



  const handleDelete = () => {

    deleteDocument(documentId).then((Resp) => {
      refetchDocs();
      setDeleteData(false);
      toast.success(t('common:documentDelete'));
    });
  };

  const options = [
    {
      icon: <DeleteIcon size="18px" />,
      onClick: (item) => { setDeleteData(true); setDocumentId(item?.id) },
    },
    {
      icon: <DownloadIcon size="18px" />,
      onClick: (item) => { window.open(downloadUrl + item?.fileDescriptor, "_blank") },
    },
    {
      icon: <ViewIcon size="18px" />,
      onClick: (item) => { handleViewDocs(item?.s3Object?.signedUrl) },
    },

  ]

  return (
    <>
      <div className="bg-white">
        <Container className="w-100" lang={lang}>
          <ContentContainer>
            <div className="text-center text-md-start">
              <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>
                {t("common:resourceDocument")}
              </StyledText>
              <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray} className="mb-0">
                {t("common:allResourcesDocument")}
              </StyledText>
            </div>
          </ContentContainer>

          <ContentContainer className="d-flex align-items-center justify-content-end gap-2">
            <ResponsiveSearch onChange={handleSearch} placeholder={t("common:searchDocument")} lang={lang} />
            <IconButtonContainer lang={lang}>
              <StyledButton
                icon={<AddIcon />}
                className="py-2 text-white"
                onClick={() => {
                  setAddDoc(true);
                }}
              />

            </IconButtonContainer>
            <TextButtonContainer lang={lang}>
              <StyledButton
                icon={<AddIcon />}
                className="py-2 text-white"
                wd={175}
                onClick={() => {
                  setAddDoc(true);
                }}
              >
                {t("common:newDocument")}
              </StyledButton>

            </TextButtonContainer>

          </ContentContainer>
        </Container>
        <StyledContainer height={isSmallScreen && '305px'} zeroState={!isLoading && (getDocuments?.data?.length == 0 || getDocuments?.data?.length == undefined)}>
          {!isLoading && (getDocuments?.data?.length == 0 || getDocuments?.data?.length == undefined) ?

            (<div className="d-flex flex-column align-items-center justify-content-center h-100">
              <div className="d-flex align-items-center justify-content-center">
                <ResourcesZeroState />
              </div>
              <StyledText
                color={theme.gray}
                $fontWeight={600}
                $fontSize="20px"
                className="text-center mt-3"
              >
                {t("common:noResourceDocument")}
              </StyledText>
              <StyledText
                color={theme.gray}
                $fontWeight={500}
                $fontSize="14px"
                className="text-center"
              >
                {t("common:noResourceDocumentDescription")}
              </StyledText>
            </div>) : isLoading ? <GridCardContainer><CardSkeleton /></GridCardContainer> :
              <GridCardContainer>
                {getDocuments?.data?.map((item, index) => (
                  <StyledResourceCard options={options} data={item} key={index} />
                ))}
              </GridCardContainer>}


        </StyledContainer>
        {isLoading ? null : !isLoading && (getDocuments?.data?.length == 0 || getDocuments?.data?.length == undefined) ? null : 
        <PaginationCommonContainer>
        <PaginationPerPage
          perPage={getDocuments?.perPage || 0}
          pageNo={getDocuments?.page || 0}
          onPerPageChange={handlePerPageChange}
          totalCount={getDocuments?.count || 0}
          onPageChange={handlePageChange}
        />
        </PaginationCommonContainer>
        }
      </div>
      <DeleteModal
        show={deleteData}
        onHide={() => setDeleteData(false)}
        handleDelete={handleDelete}
        disabled={deleteLoading}
        loading={deleteLoading}
      />
      {addDoc && (
        <AddDocumentModal
          show={addDoc}
          onHide={() => setAddDoc(false)}
          refetchDocs={refetchDocs}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </>
  );
};


const TextButtonContainer = styled.div`
display:block;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '1021px' : '1058px')}) {
            display: none;
        
    }
`;
const IconButtonContainer = styled.div`
display:none;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '1021px' : '1058px')}) {
            display: block;
        
    }
`;

const Container = styled.div`
display:flex;
align-items:center;
padding:30px 35px 0px;

justify-content:space-between;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '904px' : '946px')}) {
            flex-direction: column;
            gap:10px;


        
    }

    @media (max-width: 768px) {
             padding:15px;

        
    }
`;

const ContentContainer = styled.div`
 @media (max-width: 854px) {
            width :100%;
        
    }
`;

const ResponsiveSearch = styled(StyledIconInput)`
width: 264px;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '904px' : '946px')}) {
            width: 100%;
        
    }

`


export default Resources;
