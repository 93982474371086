import styled from "styled-components";
import theme from "../Services/Utils/themes";
import { NavLink } from "react-router-dom";


export const SidebarContainer = styled.div`
  background: ${theme.betaColor};
  color: white;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  padding: 20px 30px;
  border-radius:8px;
  @media (max-width: 768px) {
    position: fixed; /* Use fixed positioning for mobile sidebar */
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-250px')}; /* Adjust for sidebar width */
    height: 100vh; /* Ensure it covers the full height of the viewport */
    z-index: 1000; /* Ensure it's above other content */
    transition: left 0.3s ease;
    border-radius:0 8px 8px 0;
  }
`;

export const SidebarHeader = styled.div`
height: 45px;
`

export const StyledHr = styled.hr`
border: 1px solid ${theme.white};
border-radius: 10px;
width: 100%;
margin: 20px 0px;
`

export const ProfileSection = styled.div`
height: 171px;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 40px;

`

export const ContentSection = styled.div`

height: 100%;
width: 100%;
`

export const SidebarFooter = styled.div`
height: 85px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;

`

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  &.active {
    color: white;

    font-weight: 600;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 50%, rgba(255, 255, 255, 0) 100%);

  }
`;

export const Overlay = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999; /* Ensure it's above other content except the sidebar */
  }
`;