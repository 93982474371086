import React, {  useState } from "react";
import DeleteModal from "../../Modals/DeleteModal";
import AddClientModal from "../../Modals/AddClientModal";
import {
  useDeleteClientMutation,
  useGetUserListQuery,
} from "../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { svenskaLs } from "../../../Services/Utils/localStorage";
import MenuSection from "./MenuSection";
import AdminClientTable from "./AdminClientTable";
import { AdminCommonContainer } from "../../../Components/StyledComponents/CommonStyled";

const ClientWorkplace = () => {
  const { t } = useTranslation();
  const [deleteClient, { isLoading: deleteLoading }] =
    useDeleteClientMutation();
  const lang = svenskaLs.getItem("lng") || "swe";
  const [deleteData, setDeleteData] = useState(false);
  const [uID, setUId] = useState("");
  const [timer, setSearchTimer] = useState(null);
  const [addClintModalOpen, setaddClintModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState({ data: {}, state: false });
  const [sorted, setSorted] = useState(null);

  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  const [listing, setListing] = useState("");
  const {
    data: getUserList,
    refetch: refetchUsers,
    isLoading
  } = useGetUserListQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );




  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...listing };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setListing({ ...prevFilters });
  };

  const handleDelete = () => {
    const data = {
      userId: uID,
      trans: lang,
    };
    deleteClient(data).then((Resp) => {
      refetchUsers();
      setDeleteData(false);
      toast.success(t("admin:ClientDeletedSuccessfully"));
    });
  };

  return (
    <AdminCommonContainer >
      <MenuSection sorted={sorted} handleSortBy={handleSortBy} handleSearch={handleSearch} setAddClientModal={setaddClintModalOpen} setIsEdit={setIsEdit} />
      <AdminClientTable clientDetails={getUserList} handlePerPageChange={handlePerPageChange} handlePageChange={handlePageChange} setIsEdit={setIsEdit} setaddClientModalOpen={setaddClintModalOpen} setDeleteData={setDeleteData} setUId={setUId} loadClients={isLoading} refetchUsers={refetchUsers}/>
      {/* <StyledTableSkeleton cols={column} isFilter={true} /> */}
      <DeleteModal
        show={deleteData}
        onHide={() => setDeleteData(false)}
        handleDelete={handleDelete}
        disabled={deleteLoading}
        loading={deleteLoading}
      />
      {addClintModalOpen && (
        <AddClientModal
          show={addClintModalOpen}
          onHide={() => setaddClintModalOpen(false)}
          refetchUsers={refetchUsers}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </AdminCommonContainer>
  );
};


export default ClientWorkplace;
