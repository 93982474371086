import React, { useState } from "react";
import AddAdminModal from "../../Modals/AddAdminModal";
import {
  useDeleteSingleAdminMutation,
  useGetAdminListQuery,
} from "../../../Services/Admin Api/adminDashboardApi/adminWorkplaceApi/adminWPApiSlice";
import { useTranslation } from "react-i18next";
import AdminMenuSection from "./AdminMenuSection";
import AdminTable from "./AdminTable";
import { svenskaLs } from "../../../Services/Utils/localStorage";
import ConfirmDeleteModal from "../../Modals/ConfirmDeleteModal";
import { toast } from "react-toastify";
import { AdminCommonContainer } from "../../../Components/StyledComponents/CommonStyled";

const AdminWorkplace = () => {
  const { t } = useTranslation();
  const [deleteData, setDeleteData] = useState(false);
  const [timer, setSearchTimer] = useState(null);
  const [isEditAdmin, setIsEditAdmin] = useState({ data: {}, state: false });
  const [addAdminModalOpen, setaddAdminModalOpen] = useState(false);
  const [deleteAdmin, { isLoading: delAdmin }] = useDeleteSingleAdminMutation();
  const lang = svenskaLs.getItem("lng") || "swe";
  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  const [listing, setListing] = useState("");

  const {
    data: getAdminList,
    refetch: refetchAdmin,
    isLoading
  } = useGetAdminListQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [adminId, setAdminId] = useState("");



  





  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };

  const [sorted, setSorted] = useState(null);

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...listing };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setListing({ ...prevFilters });
    setFilters({ ...filters, page: 1 });
  };




  const handleDeleteAdmin = () => {
    const data = {
      adminId: adminId,
      trans: lang,
    };
    deleteAdmin(data).then((Resp) => {
      setDeleteData(false);
      refetchAdmin();
      toast.success(t("admin:AdminDeletedSuccessfully"));
    });
  };

  return (
    <AdminCommonContainer>
      <AdminMenuSection handleSortBy={handleSortBy} handleSearch={handleSearch} sorted={sorted} setIsEditAdmin={setIsEditAdmin} setaddAdminModalOpen={setaddAdminModalOpen} />
      <AdminTable setIsEditAdmin={setIsEditAdmin} setaddAdminModalOpen={setaddAdminModalOpen} setDeleteData={setDeleteData} setAdminId={setAdminId} handlePageChange={handlePageChange} handlePerPageChange={handlePerPageChange} adminDetail={getAdminList} loadAdmins={isLoading}/>

      {/* <StyledTableSkeleton cols={column} /> */}
      {deleteData && (
        <ConfirmDeleteModal
          show={deleteData}
          onHide={() => setDeleteData(false)}
          handleDelete={handleDeleteAdmin}
          disabled={delAdmin}
          loading={delAdmin}  
          placeholderText={t("admin:typeYes")}
          descriptionText={t("admin:byDeletingThisAdmin")}

        />
      )}

      {addAdminModalOpen && (
        <AddAdminModal
          show={addAdminModalOpen}
          onHide={() => setaddAdminModalOpen(false)}
          refetchAdmin={refetchAdmin}
          isEditAdmin={isEditAdmin}
          setIsEditAdmin={setIsEditAdmin}
        />
      )}
    </AdminCommonContainer>
  );
};


export default AdminWorkplace;
