import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import StyledText from "../Components/StyledComponents/StyledText";
import { svenskaLs } from "../Services/Utils/localStorage";
import * as Yup from "yup";
import {
  useCreateUserMutation,
  useGetSignupOrderRefMutation,
} from "../Services/loginApi/loginApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../Services/Auth/authSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import theme from "../Services/Utils/themes";
import { DefaulImage, useWindowSize } from "../Services/Utils/helper";
import { Form, Formik } from "formik";
import FormikInputField from "../Components/Formik/FormikInputField";
import StyledButton from "../Components/StyledComponents/StyledButton";
import { useNavigate } from "react-router-dom";
import svenskaLogo from '../Assets/Images/svenskaLogo.png'
const SignupRedirectPage = () => {
  const { t } = useTranslation();
  let orderRef = svenskaLs.getObject("orderRef");
  const lang = svenskaLs.getItem("lng") || "swe";
  const userInfo = svenskaLs.getObject("userDetails");
  const [newUser, setNewUser] = useState("");
  const isSmallScreen = useWindowSize();
  const dispatch = useDispatch();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const navigate = useNavigate();
  const [orderRefCollect] = useGetSignupOrderRefMutation();
  useEffect(() => {
    if (svenskaLs.getItem('pageReload') === null) {
      if (!userInfo && !newUser) {
        svenskaLs.setItem('pageReload', 1);
        orderRefCollect(orderRef).then((resp) => {
          if (resp?.data?.userData) {
            setNewUser(resp?.data?.userData);
            svenskaLs.removeItem('pageReload');
            svenskaLs.setObject("userDetails", resp?.data?.userData);
          } else {
            toast.error(resp?.error?.data?.message);
          }
        });
      }
    }
    else {
      navigate("/signup");
      svenskaLs.removeItem('pageReload');
      svenskaLs.removeItem("orderRef");
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    const fName = userInfo ? userInfo?.name?.split(" ") : newUser?.name?.split(" ");
    const userData = {
      firstName: fName[0] ?? "",
      lastName: fName?.slice(1)?.join(" ") ?? "",
      email: values.email,
      personalNumber: userInfo ? userInfo?.personalNumber : newUser?.personalNumber,
      trans: lang,
    };
    createUser(userData)
      .then(({ data }) => {
        if (data?.data?.token) {
          svenskaLs.removeItem("userDetails");
          dispatch(
            setCredentials({
              token: { accessToken: data?.data?.token },
              account: data?.data?.userInfo,
              userType: data?.data?.userInfo?.userType,
            })
          );
          svenskaLs.removeItem("orderRef");
          svenskaLs.setItem("loadingComplete", "true");
          navigate("/dashboard");
          // window.location.reload();

          // window.close();

          // setTimeout(() => {
          //   navigate("/dashboard");
          // }, 1000);
        } else {
          toast.error(t("common:alreadyExistErrorMessage"), {
            autoClose: 5000
          });
          setTimeout(() => {
            window.close();
          }, 5000);
        }
      })
      .catch(() => window.close());
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t('common:invalidEmail')
      )
      .required(t('common:emailRequired')),
  });

  return (
    <StyledContainer
      className={`col-10 col-sm-8 col-lg-7 d-flex flex-column align-items-center p-3 p-sm-5 ${userInfo || newUser ? "border rounded bg-white" : ""
        }`}
    >
      {userInfo || newUser ? (
        <div className="d-flex flex-column align-items-center p-md-5 ">
          <LogoContainer className="w-100 pb-5 d-flex align-items-center justify-content-center">
            <img src={svenskaLogo} width={isSmallScreen ? 191 : 292} alt="svesnkaLogo" />
          </LogoContainer>
          <StyledText
            as="h1"
            $fontWeight={600}
            $fontSize={isSmallScreen ? "20px" : "28px"}
            color={theme.secondary}
            className="text-center pb-3"
          >
            {t("common:verifyYourDetials")}
          </StyledText>
          <div className="position-relative border w-100 mt-5">
            <StyledImg
              className="rounded-circle"
              src={DefaulImage.image}
              alt=""
            />
            <div className="text-center pt-5 pb-3">
              <StyledText $fontSize="15px" $fontWeight={600}>
                {t("common:textFullName")} :
              </StyledText>{" "}
              <StyledText $fontSize="15px" color={theme.gray}>
                {userInfo ? userInfo?.name : newUser?.name}
              </StyledText>
              <br />
              <StyledText $fontSize="15px" $fontWeight={600}>
                {t("common:textPersonalNoFull")} :
              </StyledText>{" "}
              <StyledText $fontSize="15px" color={theme.gray}>
                {userInfo ? userInfo?.personalNumber : newUser?.personalNumber}
              </StyledText>
            </div>
            {/* <FormikInputField /> */}
          </div>
          <Formik
            initialValues={{
              email: undefined,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="w-100 pt-4">
              <StyledText $fontSize={isSmallScreen ? "14px" : "16px"} $fontWeight={600}>
                {t("common:textEmailAddress")} *
              </StyledText>
              <div className="pb-2" />
              <FormikInputField
                type="text"
                label="Email"
                name="email"
                placeholder="Enter your email address"
              />
              <div className=" d-flex align-items-center justify-content-center pt-5 text-center flex-wrap gap-2">
                <StyledBtn
                  type="button"
                  onClick={() => {
                    svenskaLs.removeItem("orderRef");
                    svenskaLs.removeItem("userDetails");
                    window.close();
                  }}
                  className="btn btn-light px-4"
                >
                  {t("common:textBtnCancle")}
                </StyledBtn>
                <StyledButton
                  type="submit"
                  className="btn btn-primary text-white px-4"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t("client:AccessYourWorkplace")}
                </StyledButton>
              </div>
            </Form>
          </Formik>
        </div>
      ) : (
        <>
          <div className="spinner-border text-primary loader" role="status" />
          <StyledText>Redirecting...</StyledText>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  .loader {
    height: 50px;
    width: 50px;
  }
`;

const StyledImg = styled.img`
  width: 75px;
  height: 75px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledBtn = styled.button`
  background-color: ${({ theme }) => theme.silverColor};
`;

const LogoContainer = styled.div`
  text-align: center;
  @media screen and (max-width: 575px) {
    text-align: start;
  }
`;
export default SignupRedirectPage;
