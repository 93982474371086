 const theme = {
  primary: '#C77B21',
  // primaryLight: '#0661FF1A',
  betaColor:'#35292D',
  borderPrimary: '#9BC0FF',
  lightPrimaryBg: '#2A4EDF08',
  white: '#ffffff',
  halfWhite: '#FFFFFF71',
  secondary: '#35292D',
  secondaryLight: '#989898',
  greenishGray:'#555555',
  gray: '#6C6C6C',
  lightGray: '#D3D3D3',
  dimGray:'#585558',
  hoverBg: '#F8F8F8',
  success: '#28CC0F',
  danger: '#F23F3F',
  fontFamilyBase: 'Montserrat',
  active: '#949494',
  SilverChalice: '#AAA8A8',
  silverColor: '#F6F7FA',
  bgBody:'#F1F1F1',
  lightBlue:'#F8F9FE',
  yellow:'#FFA810',
  mercuryColor:'#E4E4E4',
  fireBrick:'#CC2200',
  defaultImage: '#8C0020',
  pdfColor: '#CC4B4D',
  xlsColor: '#02AC50',
  wordColor: '#0071FF',
  pdfBgColor: 'rgba(204, 75, 77, 0.05)',
  xlsBgColoe: 'rgba(2, 172, 80, 0.05)',
  wordBgColor: 'rgba(0, 113, 255, 0.05)',
  live:'#4BD138',
  pending:'#CC4B4D',
  lightBorder:'#EFEFEF',
  bluish:'#1C79BF',

  // current use
  oxfordBlue:'#373F51',
  doveGray:'#666666',
  aliceBlue:'#F3F6FF',
  pastelGray:'#CCCCCC',
  stormDust:'#626262',
  dawn:'#A5A5A5',
  whiteSmoke:'#F6F6F6',
  stormDustGray:'#636363',
  lavenderGray:'#E1E1E1',
  cyanBlue:'#292D32',
  tealishGreen:'#28A745',
  muddyOrange:'#BF8858',
  snowWhite:'#F9F9F9',
  greenWhite:'#EAEAEA',
  flatGreen:'#649E36',
  jasperRed:'#DB4640',
  desertStormWhite:'#F7F7F7',
  darkBrown:'#222222',
  lightRed:'#f8f4f4',
};


export default theme;